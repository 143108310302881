import React, {Component} from 'react'
import ReactPlayer from 'react-player/youtube'

//const site = 'https://bwdtour.com'
const site = 'locahost:3000'
const playerStyle = {
	marginLeft: 'auto',
	marginRight: 'auto',
	position: 'absolute',
  	top:0,
  	left:0,
}
const playerConfig = {
	youtube: {
		embedOptions: {
		},
		playerVars: {
			enablejsapi: 1,
			modestbranding: 1,
			//origin: 'https://bwdtour.com',
			origin: 'https://www.youtube.com',
			playsinline: 0,
			widget_referrer: site,
			fs:1,
		}
	}
}
const rawVideos = ['https://www.youtube.com/watch?v=uQBo4LB1mGI','https://www.youtube.com/watch?v=4Jp7U37L8GE']

const videos = rawVideos.map(v => v.includes("/watch?v=") 
	? v.replace("/watch?v=", "/embed/")
	: v
	)
const ytIframeSandbox = 'allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation';

export default class VideoPage extends Component {
	constructor() {
		super();
		this.state = {
			playIndex: 0
		}
		this.playVideo = this.playVideo.bind(this)

		this.playerRef = React.createRef();
		//this.playerSandbox = this.playerSandbox.bind(this)
	}

	componentDidMount() {
		const el = document.querySelector("#widget2")
		if (el) {
			el.setAttribute('sandbox', ytIframeSandbox)
		} else {
			this.observer = new MutationObserver(() => {
				const e = document.querySelector("#widget2")
				if (e) {
					this.removeObserver()
					e.setAttribute('sandbox', ytIframeSandbox)
					//console.log(e)
				}
			})
			this.observer.observe(document, {subtree: true, childList: true})
		}
	}
	componentWillUnmount = () => {
		this.removeObserver()
	}
	removeObserver = () => {
		if (this.observer) {
			this.observer.disconnect()
			this.observer = null
		}
	}
	playerSandbox () {
		this.playerRef.current.setAttribute('sandbox', ytIframeSandbox)
	}
	playVideo(index) {
		this.setState( {playIndex: index} )
	}

	ref = player => {
		this.player = player
		this.ref.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation')
	}

	render() {
		return (<div className="App">
		<div className="AppVideo">
		<ReactPlayer 
			ref={this.playerRef}
			url={videos[this.state.playIndex]}
			width='100%'
			height='100%'
			style={playerStyle}
			config={playerConfig}
			controls={true}
			className="reactPlayer"
			playing={false}
			pip={true}
		/>

		</div>
		<div className="videoButtonContainer">
		<button className="videoButton" onClick={() => this.playVideo(0)}>
			Testify
		</button>
		<button className="videoButton" onClick={() => this.playVideo(1)}>
			Live at Third Man Records
		</button>
		</div>

		</div>
		)
	}

}
