const runReplaces = (styleBlock,edits) => {
    let content = styleBlock;
    for (let i = 0; i < edits.length; i++) {
        content = content.replace(edits[i].orig,edits[i].edited)
    }
    return content
}

let bitStyleEdit;
export default bitStyleEdit = () => {

    // editing injected styles
    const styles = document.querySelectorAll('style');
    Array.prototype.forEach.call(styles, function(el, i) {
        let content = el.textContent;
        
        if (content.indexOf('.bit-widget .bit-event .bit-offers-container {') > -1) {
            
            let edits = [
                {
                    orig: `.bit-widget .bit-event .bit-offers-container {
      flex-basis: 100%;
      width: 100%; }`,
                    edited: `.bit-widget .bit-event .bit-offers-container {
      flex-basis: 100%; }`
                },
                {
                    orig: `.bit-widget .bit-event .bit-rsvp-container {
      flex-basis: 100%;
      width: 100%;
      margin-top: 5px; }`,
                    edited: `.bit-widget .bit-event .bit-rsvp-container {
      flex-basis: 100%;
      margin-top: 5px; }`
                },
                {
                    orig: `.bit-widget .bit-event .bit-rsvp-text {
      width: max-content; }`,
                    edited: ''
                }
            ];


           el.textContent = runReplaces(el.textContent,edits)

        }
    });
}
