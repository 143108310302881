import thirdparty from './thirdparty'
import bitStyleEdit from './bit-style-edit'
import bitForceLayoutIPad from './bit-force-ipad-layout'


const togglePrivacy = (el) => {
	const action = ('classList' in el.target && el.target.classList.contains('active')) ? 'remove' : 'add'
	const p = document.getElementById('privacy')
	el.target.classList[`${action}`]('active')
	p.classList[`${action}`]('active')
	document[`${action}EventListener`]('click',outsideClickPrivacy)
}
const outsideClickPrivacy = (el) => {
	const p = document.getElementById('privacy')
	const pNav = document.getElementById('footer-privacy')
	if (!el.target.closest('#privacy') && el.target.id !== 'footer-privacy') {
		p.classList.remove('active')
		pNav.classList.remove('active')
		document.removeEventListener('click', outsideClickPrivacy)
	}
}
const toggleBook = (el) => {
	const action = ('classList' in el.target && el.target.classList.contains('active')) ? 'remove' : 'add'
	const b = document.getElementById('booking')
	el.target.classList[`${action}`]('active')
	b.classList[`${action}`]('active')
	document[`${action}EventListener`]('click',outsideClickBook)
}
const outsideClickBook = (el) => {
	const b = document.getElementById('booking')
	const bNav = document.getElementById('footer-booking')
	if (!el.target.closest('#booking') && el.target.id !== 'footer-booking') {
		b.classList.remove('active')
		bNav.classList.remove('active')
		document.removeEventListener('click', outsideClickBook)
	}
}
const toggleTestify = (el) => {
	const action = ('classList' in el.target && el.target.classList.contains('active')) ? 'remove' : 'add'
	const b = document.getElementById('testify')
	el.target.classList[`${action}`]('active')
	b.classList[`${action}`]('active')
	document[`${action}EventListener`]('click',outsideClickTestify)
}
const outsideClickTestify = (el) => {
	const b = document.getElementById('testify')
	const bNav = document.getElementById('footer-testify')
	if (!el.target.closest('#testify') && el.target.id !== 'footer-testify') {
		b.classList.remove('active')
		bNav.classList.remove('active')
		document.removeEventListener('click', outsideClickTestify)
	}
}
// VIDEO PLAYER HIDE/SHOW
const outsideClickVideoPlayer = (el) => {
	const b = document.getElementById('comedySpecials')
	const bNav = document.getElementById('footer-comedy-specials')
	if (!el.target.closest('#comedySpecials') && el.target.id !== 'footer-comedy-specials') {
		b.classList.remove('active')
		bNav.classList.remove('active')
		document.removeEventListener('click', outsideClickVideoPlayer)
	}
	// SHOW NAV ELEMENTS AGAIN
}

const toggleVideoPlayer = (el) => {
	const action = ('classList' in el.target && el.target.classList.contains('active')) ? 'remove' : 'add'
	const b = document.getElementById('comedySpecials')
	el.target.classList[`${action}`]('active')
	b.classList[`${action}`]('active')
	document[`${action}EventListener`]('click',outsideClickVideoPlayer)

	// HIDE NAV ELEMENTS for PLAYER
}
const autoOpenVideoPlayer = () => {
	const action = 'add'
	const b = document.getElementById('comedySpecials')
	b.classList[`${action}`]('active')
	document[`${action}EventListener`]('click',outsideClickVideoPlayer)
}
// EOF VIDEO PLAYER HIDE/SHOW

const outsideClick = (el) => {
	const td = document.getElementById('tourdates')
	const tdlNav = document.getElementById('footer-tour')
	if (!el.target.closest('#tourdates') && el.target.id !== 'footer-tour') {
		td.classList.remove('active')
		tdlNav.classList.remove('active')
		tdlNav.addEventListener('mouseover', hover2)
	}
}

const toggleTDs = (el) => {

	const action = ('classList' in el.target && el.target.classList.contains('active')) ? 'remove' : 'add'
	const td = document.getElementById('tourdates')
	el.target.classList[`${action}`]('active')
	td.classList[`${action}`]('active')

	if (action === 'add') {

		bitStyleEdit();
		bitForceLayoutIPad();
	}
	
	const listenerAction = (action === 'add') ? 'remove' : 'add'
	el.target[`${listenerAction}EventListener`]('mouseover',hover2)

	document[`${action}EventListener`]('click',outsideClick)
	closeMsg()
}
const outboundLink = (el) => {
	const {id} = el.target
	const url = thirdparty[id].base + thirdparty[id].user
	window.location = url
}
const closeMsg = (el) => {
	const msg = el || document.getElementById('messages')
	msg.innerHTML = ''
	msg.setAttribute("style", "display:none")
}
const hover = (el) => {
	const {id} = el.target
	const msg = document.getElementById('messages')
	if (el.type === 'mouseover') {
		msg.innerHTML = `<h2>${thirdparty[id].cta}</h2>`
		msg.setAttribute("style", "display:block")
	} else if (el.type === 'mouseout') {
		closeMsg(msg)
	}
}
const hover2 = (el) => {
	const msg = document.getElementById('messages')
	if (el.type === 'mouseover') {
		msg.innerHTML = "<h2>Click for BWD's Tour Dates</h2>"
		msg.setAttribute("style", "display:block")
	} else if (el.type === 'mouseout') {
		closeMsg(msg)
	}
}

const eventListeners = () => {
	document.addEventListener('DOMContentLoaded', function(e) {
		//auto call comedy specials open
		autoOpenVideoPlayer();
		// tour dates
		const tdlNav = document.getElementById('footer-tour')
		tdlNav.addEventListener('click', toggleTDs)
		tdlNav.addEventListener('mouseover', hover2)
		tdlNav.addEventListener('mouseout', hover2)
		// booking
		const booking = document.getElementById('footer-booking');
		booking.addEventListener('click', toggleBook)
		//Testify
		const testify = document.getElementById('footer-testify');
		testify.addEventListener('click', toggleTestify)
		//comedy specials
		const comedySpecials = document.getElementById('footer-comedy-specials');
		comedySpecials.addEventListener('click', toggleVideoPlayer)
		// privacy
		const pNav = document.getElementById('footer-privacy')
		pNav.addEventListener('click',togglePrivacy)

		const outbound = ['instagram', 'twitter', 'youtube', 'bandsintown','patreon']
		outbound.forEach(o => {
			const link = document.getElementById(o)
			link.addEventListener('click', outboundLink)
			link.addEventListener('mouseover', hover)
			link.addEventListener('mouseout', hover)
		})
	})
}
export default eventListeners